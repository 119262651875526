<template>
  <div class="background" v-show="store.state.guestAddPopupView">
    <!-- <div class="background" v-show="true"> -->

    <div class="popWapper__default">
      <div class="popHeader">
        <div class="popHeader-title">게스트 등록</div>
        <button class="pop__invisible" @click="onClickClose"></button>
      </div>

      <div class="popContainer__default">
        <!-- 첫번째 행 -->
        <div class="wrap-input flex-spaceBetween" style="width: 725px">
          <!-- 구분 input -->
          <div>
            <p class="label-title">구분 &nbsp;<span>*</span></p>
            <div class="wrap-dropdown relative" @click="isOpen = !isOpen">
              <input
                class="input-dropdown"
                v-model="state.type"
                placeholder="GUEST 구분"
                readonly
              />
              <div class="down-arrow" :class="{ 'up-arrow': isOpen }"></div>
            </div>
            <div v-if="isOpen" ref="dropboxRef" class="wrap-dropbox-content">
              <div
                v-for="(option, index) in options"
                :key="index"
                @click="onClickSelectOption(option)"
              >
                {{ option }}
              </div>
            </div>
          </div>
          <!-- 학교이름 ('초등학교' 제외 입력) input -->
          <div>
            <p class="label-title">
              학교이름 ('초등학교' 제외 입력) &nbsp;<span style="color: red"
                >*</span
              >
            </p>
            <input
              v-model="state.schoolName"
              type="text"
              class="input-no-icon"
              placeholder="학교 이름을 입력해주세요."
            />
          </div>
          <div>
            <p class="label-title">베트남 계정 여부</p>
            <input
              type="checkbox"
              v-model="state.language"
              style="width: 30px; height: 30px; margin-top: 3px"
            />
          </div>
        </div>
        <!-- 두번째 행 -->
        <div class="wrap-input flex-spaceBetween" style="width: 870px">
          <div>
            <p class="label-title">이름 &nbsp;<span>*</span></p>
            <input
              v-model="addData.contact.name"
              type="text"
              class="input-no-icon"
              placeholder="텍스트를 입력해 주세요."
            />
          </div>
          <div>
            <p class="label-title">이메일 주소 &nbsp;<span>*</span></p>
            <input
              v-model="addData.contact.mail"
              type="text"
              class="input-no-icon"
              placeholder="텍스트를 입력해 주세요."
            />
          </div>
          <div>
            <p class="label-title">전화 번호 &nbsp;<span>*</span></p>
            <input
              v-model="addData.contact.tel"
              type="text"
              class="input-no-icon"
              placeholder="텍스트를 입력해 주세요."
            />
          </div>
        </div>
      </div>

      <div class="popFooter">
        <button class="flex-right primary btn" @click="onClickCheck">
          확인
        </button>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted, reactive, computed, defineProps } from "vue";
import { useStore } from "vuex";
import { onClickOutside } from "@vueuse/core";
import "../../assets/style/popupStyle.css";
import "../../assets/style/titleStyle.css";
import "../../assets/style/wrapStyle.css";
import "../../assets/style/buttonStyle.css";
import "../../assets/style/inputStyle.css";
import "../../assets/style/dropdownStyle.css";

const store = useStore();

const state = reactive({
  schoolName: "",
  schoolLevel: "guest",
  type: "",
  office: "-",
  location: "-",
  language: false,
});

const addData = reactive({
  schoolCode: "",
  contact: {
    name: "",
    tel: "",
    mail: "",
    telCheck: false,
    mailCheck: false,
    memo: "망태기 할아버지",
  },
});

const props = defineProps(["school"]);
const isOpen = ref(false);
const dropboxRef = ref(null);

// console.log('popup : ', props.school)

const options = ref([
  "교사 연수",
  "학교 체험 (구글폼)",
  "학교 체험 (수기 입력)",
  "기타 (기업 등)",
]);

const onClickSelectOption = (option) => {
  state.type = option;
  isOpen.value = false;
};

const onClickClose = () => {
  store.commit("SET_GUSET_ADD_POPUP_VIEW", false);
  state.schoolName = "";
  state.type = "";
  addData.contact.name = "";
  addData.contact.mail = "";
  addData.contact.tel = "";
};

const checkValues = () => {
  const checkState = Object.values(state).some((check) => check.trim() !== "");
  const checkAddData =
    addData.contact.name.trim() !== "" &&
    addData.contact.mail.trim() !== "" &&
    addData.contact.tel.trim() !== "";
  // console.log(checkState, checkAddData)
  if (checkState === checkAddData) return true;
  else return false;
};

const onCreateGuest = async () => {
  const getCode = await store.dispatch("POST_SCHOOL_CREATE", state);
  if (getCode.result === true) {
    addData.schoolCode = getCode.data.schoolCode;
    const result = await store.dispatch("POST_SCHOOL_CREATE_CONTACT", addData);
    if (result.data === true) {
      alert("GUEST가 추가되었습니다.");
      // onClickClose()
      window.location.reload(true);
    } else {
      alert("GUSET가 생성되지 않았습니다.");
    }
  } else {
    alert("GUEST생성에 오류가 발생하였습니다.");
  }
};

const checkSchoolName = () => {
  const schoolNames = Object.values(props.school).some(
    (v) => v === state.schoolName
  );
  return schoolNames;
};

const onClickCheck = async () => {
  if (checkValues() === true && checkSchoolName() === false) {
    if (window.confirm("추가하시겠습니까?")) {
      onCreateGuest();
    } else {
      console.log("취소");
    }
  } else if (checkValues() === true && checkSchoolName() === true) {
    if (
      window.confirm("이미 동일한 이름의 학교가 있습니다. 추가하시겠습니까?")
    ) {
      onCreateGuest();
    } else {
      console.log("취소");
    }
  } else if (checkValues() === false) {
    alert("필수 사항을 모두 작성해주세요");
  } else {
    alert("호에에엑 무슨 일이지");
  }
};

onMounted(() => {
  onClickOutside(dropboxRef, () => {
    isOpen.value = false;
  });
});
</script>

<style scoped>
</style>