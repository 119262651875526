import { instanceAuth } from './url'

const getSearchGuest = async () => {
  return await instanceAuth
    .get('/schools/search/guest/list', { credentials: true })
    .catch((err) => {
      console.log('actions err : ', err)
    })
}

const getSearchGuestYeonsu = async () => {
  return await instanceAuth
    .get('/schools/search/guest/yeonsu/list', { credentials: true })
    .catch((err) => {
      console.log('actions err : ', err)
    })
}

const getSearchGuestGeneral = async () => {
  return await instanceAuth
    .get('/schools/search/guest/general/list', { credentials: true })
    .catch((err) => {
      console.log('actions err : ', err)
    })
}

const getSchoolCreate = async () => {
  return await instanceAuth
    .get('/schools/guest', { credentials: true })
    .catch((err) => {
      console.log('actions err : ', err)
    })
}

const postSchoolCreate = async (data) => {
  if (data.language === false) {
    data.language = 0
  } else {
    data.language = 1
  }

  return await instanceAuth
    .post('/schools/create', data, { credentials: true })
    .catch((err) => {
      console.log('actions err : ', err)
    })
}

const postSchoolCreateContact = async (data) => {
  return await instanceAuth
    .post('/schools/create/contact', data, { credentials: true })
    .catch((err) => {
      console.log('actions err : ', err)
    })
}

const getMemo = async (code) => {
  return await instanceAuth
    .get(`/schools/memo/${code}`, { credentials: true })
    .catch((err) => {
      console.log('actions err : ', err)
    })
}

const postUpdateMemo = async (data) => {
  return await instanceAuth
    .post(`/schools/update/memo/`, data, { credentials: true })
    .catch((err) => {
      console.log('actions err : ', err)
    })
}

const getContractInfo = async (code) => {
  return await instanceAuth
    .get(`/schools/info/${code}/year/contract`, { credentials: true })
    .catch((err) => {
      console.log('actions err : ', err)
    })
}

const postUpdateContractInfo = async (data) => {
  return await instanceAuth
    .post(`/schools/update/info/contract`, data, { credentials: true })
    .catch((err) => {
      console.log('actions err : ', err)
    })
}

const getGuestClassList = async (code) => {
  return await instanceAuth
    .get(`/schools/class/list/${code}`, { credentials: true })
    .catch((err) => {
      console.log('actions err : ', err)
    })
}

const getGuestStudentList = async (data) => {
  return await instanceAuth
    .get(`/users/user/${data.code}/${data.grade}/${data.classNum}`, {
      credentials: true
    })
    .catch((err) => {
      console.log('actions err : ', err)
    })
}

const getContactInfo = async (code) => {
  // console.log('guest-api-list getContractInfo : ', code)
  return await instanceAuth
    .get(`/schools/contact/${code}`, { credentials: true })
    .catch((err) => {
      console.log('actions err : ', err)
    })
}

const postUpdateContactInfo = async (data) => {
  // console.log('guest-api-list getContractInfo : ', code)
  return await instanceAuth
    .post(`/schools/update/contact`, data, { credentials: true })
    .catch((err) => {
      console.log('actions err : ', err)
    })
}

const getSubscription = async () => {
  return await instanceAuth
    .get(`/schools/search/subscription`, { credentials: true })
    .then((res) => {
      return res
    })
    .catch((err) => {
      console.log('actions err : ', err)
      return 'error'
    })
}

const postCreateGuest = async (data) => {
  console.log('guest-api-list postCreateGuest : ', data)
  return await instanceAuth
    .post(`/users/create/guest`, data, { credentials: true })
    .catch((err) => {
      console.log('actions err : ', err)
    })
}

const deleteSubsciption = async (idx) => {
  return await instanceAuth
    .delete(`/schools/delete/subscription/${idx}`, { credentials: true })
    .catch((err) => {
      console.log('actions err : ', err)
    })
}

const postCreateTraining = async (data) => {
  return await instanceAuth
    .post(`/users/create/training`, data, { credentials: true })
    .catch((err) => {
      console.log('actions err : ', err)
    })
}

const getCheckTrainingContact = async (code) => {
  return await instanceAuth
    .get(`/schools/check/training/contact/${code}`, { credentials: true })
    .catch((err) => {
      console.log('actions err : ', err)
    })
}

export {
  getSearchGuest,
  getSearchGuestYeonsu,
  getSearchGuestGeneral,
  getSchoolCreate,
  postSchoolCreate,
  postSchoolCreateContact,
  getMemo,
  postUpdateMemo,
  getContractInfo,
  postUpdateContractInfo,
  getGuestClassList,
  getGuestStudentList,
  getContactInfo,
  postUpdateContactInfo,
  getSubscription,
  postCreateGuest,
  deleteSubsciption,
  postCreateTraining,
  getCheckTrainingContact
}
